.full-width {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4 {
  font-weight: normal;
}
html {
  font-size: 20px;
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  color: #666666;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
header {
  width: 100%;
  height: 70px;
  background-color: white;
  z-index: 120;
  position: fixed;
  top: 0;
  flex-basis: auto;
}
header nav {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 70px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em;
}
header nav .logo {
  font-size: 2em;
  text-decoration: none;
  color: #666666;
}
header nav .logo sub {
  bottom: -14px;
}
header nav .hamburger {
  cursor: pointer;
  display: none;
}
header nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  justify-content: space-between;
}
header nav ul li {
  font-size: .8em;
  margin-left: 2em;
}
header nav ul li a {
  color: #666666;
  text-decoration: none;
}
header nav ul li a:hover {
  color: black;
}
footer {
  background-color: #666666;
  color: white;
  flex-basis: auto;
}
footer .blocks {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
footer .blocks .thirds {
  width: 30%;
}
footer .blocks .thirds p,
footer .blocks .thirds ul {
  font-size: .9em;
}
footer .blocks .thirds a,
footer .blocks .thirds a:visited,
footer .blocks .thirds a:active {
  color: white;
}
footer .blocks .social ul li {
  display: inline-block;
}
footer .blocks .social ul li a span {
  background: url(/img/ftr-sprit.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  margin: 0 0.5em 0 0;
}
footer .blocks .social ul li a span.fa {
  background-position: 0 0;
  opacity: 1.0;
}
footer .blocks .social ul li a span.tw {
  background-position: -34px 0;
  opacity: 1.0;
}
footer .blocks .social ul li a span.g {
  background-position: -68px 0;
  opacity: 1.0;
}
footer .blocks .social ul li a span.in {
  background-position: -102px 0;
  opacity: 1.0;
}
footer .legal {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  font-size: .7em;
  text-align: center;
}
button,
.button,
input[type=submit] {
  background-color: black;
  color: white !important;
  text-decoration: none !important;
  padding: 8px 20px;
  border: none;
}
button:hover,
.button:hover,
input[type=submit]:hover {
  background-color: #292929;
}
#container {
  width: 100%;
  background: top center no-repeat;
  background-size: cover;
  margin-top: 70px;
  height: auto;
  flex: 1;
}
#container #content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2em 2em 2em;
}
article {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4em 2em 2em 2em;
  width: 60%;
  color: #666666;
}
article h1 {
  margin: 0;
  line-height: 1.1;
}
article p {
  line-height: 1.8;
}
article a,
article a:visited,
article a:active {
  color: #666666;
}
article.right + .content {
  justify-content: flex-end;
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.products .product {
  width: 48%;
  flex-basis: 48%;
}
.products .product img {
  width: 100%;
}
