@text: #666666;
@darkgrey: #292929;
@mediumgrey: #a9a9a9;
@header-height: 70px;

.full-width {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

*, *:before, *:after {
    box-sizing: border-box;
}

h1, h2, h3, h4 {
    font-weight: normal;
}

html {
    font-size: 20px;
    font-family: 'EB Garamond', serif;
    font-weight: normal;
    color: @text;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

header {
    width: 100%;
    height: @header-height;
    background-color: white;
    z-index: 120;
    position: fixed;
    top: 0;
    flex-basis: auto;
    nav {
        .full-width;
        height: @header-height;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 2em;
        .logo {
            font-size: 2em;
            text-decoration: none;
            color: @text;
            sub {
                bottom: -14px;
            }
        }
        .hamburger {
            cursor: pointer;
            display: none;
        }
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style-type: none;
            justify-content: space-between;
            li {
                font-size: .8em;
                margin-left: 2em;
                a {
                    color: @text;
                    text-decoration: none;
                    &:hover {
                        color: black;
                    }
                }
            }
        }
    }
}

footer {
    background-color: @text;
    color: white;
    flex-basis: auto;
    .blocks {
        .full-width;
        display: flex;
        justify-content: space-around;
        .thirds {
            width: 30%;
            p, ul {
                font-size: .9em;
            }
            a, a:visited, a:active {
                color: white;
            }
        }
        .social {
            ul {
                li {
                    display: inline-block;
                    a {
                        span {
                            background: url(/img/ftr-sprit.png) no-repeat;
                            width: 32px;
                            height: 32px;
                            display: inline-block;
                            margin: 0 0.5em 0 0;
                            &.fa {
                                background-position: 0 0;
                                opacity: 1.0;
                            }
                            &.tw {
                                background-position: -34px 0;
                                opacity: 1.0;
                            }
                            &.g {
                                background-position: -68px 0;
                                opacity: 1.0;
                            }
                            &.in {
                                background-position: -102px 0;
                                opacity: 1.0;
                            }
                        }
                    }
                }
            }
        }
    }
    .legal {
        .full-width;
        width: 100%;
        font-size: .7em;
        text-align: center;
    }
}

button, .button, input[type=submit] {
    background-color: black;
    color: white !important;
    text-decoration: none !important;
    padding: 8px 20px;
    border: none;
    &:hover {
        background-color: @darkgrey;
    }
}

#container {
    width: 100%;
    background: top center no-repeat;
    background-size: cover;
    margin-top: @header-height;
    height: auto;
    flex: 1;
    #content {
        .full-width;
        padding: 0 2em 2em 2em;
    }
}

article {
    background-color: fade(white, 90);
    padding: 4em 2em 2em 2em;
    width: 60%;
    color: @text;
    h1 {
        margin: 0;
        line-height: 1.1;
    }
    p {
        line-height: 1.8;
    }
    a, a:visited, a:active {
        color: @text;
    }
    &.right {
        &+.content {
            justify-content: flex-end;
        }
    }
}

.products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .product {
        width: 48%;
        flex-basis: 48%;
        img {
            width: 100%;
        }
    }
}